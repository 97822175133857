<div class="toolbar" role="banner">
  <div class="content-banner" role="main">
    <label>Platana Task Permissions Editor</label>
  </div>
  <div class="spacer"></div>
  <div class="content-banner" *ngIf="userProfile">
    <label>{{ userProfile.email }}</label>
  </div>
  <mat-icon role="img" class="btn-logout mat-icon notranslate material-icons mat-icon-no-color" aria-hidden="true" (click)="logout()">exit_to_app</mat-icon>
</div>

<div class="content" role="main">
  <app-task-selector/>
</div>

<router-outlet></router-outlet>
