
<div class="modal-permission-editor">
  <app-permission-editor #permissionEditor (newPermissionEvent)="onNewPermission($event)"></app-permission-editor>
</div>


<div class="view-task-selector">
  <div class="listbox-task-domains">
    <div class="label">Task Domains (Categories Platana)</div>
    <ejs-listbox cssClass="domainbox" #taskDomainsListBox [dataSource]='taskDomains' [selectionSettings]='selection'
                 (change)="onTaskDomain($event)"
                 [fields]="setField"></ejs-listbox>
  </div>

  <div class="listbox-tasks">
    <div class="label">Tasks (Scopes Platana)</div>
    <ejs-listbox cssClass="taskbox"  #tasksListBox [dataSource]='tasks' [selectionSettings]='selection' (change)="onTask($event)"
                 [fields]="setField"></ejs-listbox>
  </div>

  <div *ngIf="loading" class="container-permissions">
    <div class="label">Loading ...</div>
  </div>

  <div *ngIf="rs2permissions.size > 0" class="container-permissions">
    <div class="label">Permissions</div>

    <div class="container-resource-server" *ngFor="let resourceServerId of Array.from(this.rs2permissions.keys()) ">
      <div class="label-permission">
        <a target="_blank" href="{{keycloakUrl}}/admin/master/console/#/{{keycloakRealm}}/clients/{{resourceServerId}}/authorization/resources"> {{ getRessourceServer(resourceServerId).clientId }}</a>
        <button style="float: right; margin-right: 20px;" (click)="showAddPermissionModal(resourceServerId)">Add a Task Permission</button>
      </div>

      <div class="container-permission" *ngFor="let permission of rs2permissions.get(resourceServerId)">
        <div style="float: left">
          <div class="container-resource" *ngFor="let resource of permission.resources">
            <label><b>{{ resource.name }}</b><br/> ({{ resource.uris }}) </label> <br/>
          </div>
          <div *ngIf="permission.type === 'resource'" class="container-resource">
          <span class="container-scopes" *ngFor="let scope of permission.resources[0].scopes">
            [ {{ scope.name }} ]
          </span>
            <label> (all) </label>
          </div>
          <div *ngIf="permission.type === 'scope'" class="container-resource">
          <span class="container-scopes" *ngFor="let scope of permission.scopes">
            [ {{ scope.name }} ]
          </span>
          </div>
        </div>
        <div style="float: left;">
          <mat-icon (click)="deletePermission(resourceServerId, permission.id)" role="img" class="mat-icon notranslate material-icons mat-icon-no-color" aria-hidden="true">
            delete
          </mat-icon>
        </div>
      </div>
    </div>

  </div>
  <div *ngIf="rs2permissions.size === 0 && selectedTaskLabel !== '' " class="container-permissions">
    <button style="margin: 100px" (click)="showAddPermissionModal('null')">Add a Task permission for {{selectedTaskLabel}}</button>
  </div>
</div>
