import {Component, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {PermissionsService} from "../permissions.service";
import {single} from "rxjs";
import {ChangeEventArgs, ListBox} from "@syncfusion/ej2-angular-dropdowns";
import {NewPermissionEvent, Policy} from "../business-objects";
import {environment} from '../../environments/environment';
import {PermissionEditorComponent} from "../permission-editor/permission-editor.component";

@Component({
  selector: 'app-task-selector',
  templateUrl: './task-selector.component.html',
  styleUrls: ['./task-selector.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class TaskSelectorComponent implements OnInit {

  public taskDomains: Policy[] = [];
  public tasks: Policy[] = [];
  public rs2permissions: Map<string, Policy[]> = new Map<string, Policy[]>();

  private selectedTaskDomainLabel: string = ''
  public selectedTaskLabel: string = ''

  public loading: boolean = false

  @ViewChild('permissionEditor')
  public permissionEditor: PermissionEditorComponent

  @ViewChild('taskDomainsListBox')
  public taskDomainsListBox: ListBox

  @ViewChild('tasksListBox')
  public tasksListBox: ListBox

  public keycloakUrl = environment.keycloakUrl

  public keycloakRealm = environment.keycloakRealm;

  constructor(
    private permissionService: PermissionsService
  ) {
  }

  ngOnInit(): void {
    this.getTasks('TASKDOMAIN:')
  }

  getTasks(searchPattern: string) {
    this.permissionService.getTasks(searchPattern).subscribe(tasks => this.taskDomains = tasks);
  }

  getRessourceServer(ressourceServerId: string) {
    return this.permissionService.resourceServers.filter(resourceServer => resourceServer.id == ressourceServerId)[0]
  }

  onTaskDomain(e: ChangeEventArgs): void {
    (this.tasksListBox as any).value = null;
    this.selectedTaskLabel = ''
    this.selectedTaskDomainLabel = e.value.toString()
    this.permissionService.getTasks('TASK:'.concat(this.selectedTaskDomainLabel)).subscribe(tasks => this.tasks = tasks);
    this.tasks = []
    this.rs2permissions = new Map<string, Policy[]>();
  }

  onTask(e: ChangeEventArgs): void {
    this.selectedTaskLabel = e.value.toString()
    this.loading = true
    this.permissionService.getPermissions('PERMISSION:'
      .concat(this.selectedTaskDomainLabel)
      .concat('/')
      .concat(this.selectedTaskLabel)
      .concat(':'))
      .subscribe(rs2permissions => {
        this.rs2permissions = rs2permissions
        this.loading = false
      });
    this.rs2permissions = new Map<string, Policy[]>();
  }

  public setField = {
    text: "label",
    value: "label"
  }

  public selection = {mode: "Single"}

  protected readonly single = single;
  protected readonly Array = Array;


  onNewPermission(newPermissionEvent: NewPermissionEvent) {
    this.showPermissionsForTask(newPermissionEvent)
  }

  showPermissionsForTask(newPermissionEvent: NewPermissionEvent) {
    this.rs2permissions = new Map<string, Policy[]>();
    this.permissionService.getTasks('TASKDOMAIN:')
      .subscribe(taskDomains => {
        this.taskDomains = taskDomains
        this.taskDomainsListBox.selectItems([newPermissionEvent.taskDomainLabel])
        this.tasksListBox.selectItems([newPermissionEvent.taskLabel])
      })
  }

  deletePermission(resourceServerId: string, permissionId: string) {
    if (confirm("Are you sure you want to remove the permission?")) {
      this.permissionService.deletePermission(resourceServerId, permissionId).subscribe(then => {
        // refresh
        this.showPermissionsForTask({
          taskLabel: this.selectedTaskLabel,
          taskDomainLabel: this.selectedTaskDomainLabel
        })
      })
    }
  }

  showAddPermissionModal(resourceServerId: string) {
    console.log(resourceServerId)
    this.permissionEditor.modalDialog.show();
    this.permissionEditor.taskDomainsComboBox.value = this.selectedTaskDomainLabel;
    this.permissionService.getTasks('TASK:'.concat(this.selectedTaskDomainLabel)).subscribe(tasks => {
      this.permissionEditor.tasks = tasks
      this.permissionEditor.tasksComboBox.value = this.selectedTaskLabel;
      this.permissionEditor.resourceServers = this.permissionService.resourceServers;
      this.permissionEditor.resourceServersComboBox.value = resourceServerId;
    });
  }

}
