<div id="modalTarget" #targetModal class="col-md-8 control-section" style="height:500px;">
  <!-- Render Button to open the modal Dialog -->
  <button ejs-button id='modalbtn' #modalButton (click)="modalBtnClick()">Add a task permission</button>
  <!-- Render modal Dialog -->
  <ejs-dialog id="modalDialog" #modalDialog [isModal]='isModal' [header]='header' [visible]="false"
              [target]='target' [width]='width' [buttons]='buttons' [animationSettings]='animationSettings'
              (open)="modalDlgOpen()" (close)="modalDlgClose()">
    <ng-template #content>
      <div class="content-wrapper" style="margin-bottom: 25px;">
        <div>
          <label> Task Domain (Catégorie Platana) </label>
        </div>
        <ejs-combobox id='taskDomains' #taskDomainsComboBox [dataSource]='taskDomains' (change)='onTaskDomain($event)'
                      [fields]='setPolicyField'></ejs-combobox>
        <div>
          <label> Task (Scope Platana)</label>
        </div>
        <ejs-combobox [enabled]="false" id='tasks' #tasksComboBox [dataSource]='tasks' (change)='onTask($event)'
                      [fields]='setPolicyField'></ejs-combobox>
        <div>
          <label> Resource Server </label>
        </div>
        <ejs-dropdownlist [enabled]="false" id='resourceServers' #resourceServersComboBox [dataSource]='resourceServers'
                          (change)='onResourceServer($event)' [fields]='setResourceServerField'></ejs-dropdownlist>
        <div>
          <label> Resource </label>
        </div>
        <ejs-combobox [enabled]="false" id='resources' #resourcesComboBox [dataSource]='resources'
                      (change)='onResource($event)' [fields]='setResourceField'></ejs-combobox>
        <div>
          <label> Scopes (optional) </label>
        </div>
        <ejs-multiselect id='scopes' #scopesMultiSelect [dataSource]='scopes' [allowCustomValue]=true [mode]='box'
                         [fields]='setScopeField'></ejs-multiselect>
      </div>
    </ng-template>
  </ejs-dialog>
</div>
