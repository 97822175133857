import {AfterViewInit, Component, EventEmitter, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {AnimationSettingsModel, DialogComponent} from '@syncfusion/ej2-angular-popups';
import {ButtonComponent, ButtonModel} from '@syncfusion/ej2-angular-buttons';

import {NewPermissionEvent, Policy, Resource, ResourceServer, Scope} from "../business-objects";
import {PermissionsService} from "../permissions.service";
import {
  ChangeEventArgs,
  ComboBoxComponent,
  DropDownListComponent,
  MultiSelectComponent
} from "@syncfusion/ej2-angular-dropdowns";

@Component({
  selector: 'app-permission-editor',
  templateUrl: './permission-editor.component.html',
  styleUrls: ['./permission-editor.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class PermissionEditorComponent implements AfterViewInit {

  @ViewChild('taskDomainsComboBox')
  public taskDomainsComboBox: ComboBoxComponent;

  @ViewChild('tasksComboBox')
  public tasksComboBox: ComboBoxComponent;

  @ViewChild('resourceServersComboBox')
  public resourceServersComboBox: DropDownListComponent;

  @ViewChild('resourcesComboBox')
  public resourcesComboBox: ComboBoxComponent;

  @ViewChild('scopesMultiSelect')
  public scopesMultiSelect: MultiSelectComponent;

  @ViewChild('modalDialog')
  public modalDialog: DialogComponent;

  @ViewChild('modalButton')
  public modalButton: ButtonComponent;

  public target: string = '#modalTarget';
  public width: string = '335px';
  public header: string = 'Add a task permission';
  public isModal: Boolean = true;
  public animationSettings: AnimationSettingsModel = {effect: 'None'};
  public box: string = 'Box'

  public taskDomains: Policy[] = [];
  public tasks: Policy[] = [];
  public resourceServers: ResourceServer[] = [];
  public resources: Resource[] = [];
  public scopes: Scope[] = [];

  @Output() newPermissionEvent = new EventEmitter<NewPermissionEvent>();

  constructor(private permissionService: PermissionsService) {
  }

  onTaskDomain(e: ChangeEventArgs): void {
    if ((e.value !== null)) {
      let selectedTaskDomainLabel = e.value.toString()
      this.permissionService.getTasks('TASK:'.concat(selectedTaskDomainLabel)).subscribe(tasks => {
        this.tasks = tasks
      });
      // deselect
      this.tasksComboBox.enabled = true;
      (this.tasksComboBox as any).value = null;
      (this.resourceServersComboBox as any).value = null;
      this.resources = [];
      (this.resourcesComboBox as any).value = null;
      this.scopes = [];
      (this.scopesMultiSelect as any).value = null;
      this.tasksComboBox.enabled = true
    }
  }

  onTask(e: ChangeEventArgs): void {
    if ((e.value !== null)) {
      // deselect
      this.resources = [];
      (this.resourcesComboBox as any).value = null;
      this.scopes = [];
      (this.scopesMultiSelect as any).value = null;
      this.resourceServersComboBox.enabled = true
    }
  }

  onResourceServer(e: ChangeEventArgs): void {
    if ((e.value !== null)) {
      let resourceServerId: string = e.value.toString()
      this.permissionService.getResources(resourceServerId).subscribe(resources => this.resources = resources);
      // deselect
      (this.resourcesComboBox as any).value = null;
      this.scopes = [];
      (this.scopesMultiSelect as any).value = null;
      this.resourcesComboBox.enabled = true
    }
  }

  onResource(e: ChangeEventArgs): void {
    if ((e.value !== null)) {
      let resourceId: string = e.value.toString();
      if (this.permissionService.isUUID(resourceId)) {
        let resourceServerId: string = this.resourceServersComboBox.value.toString();
        this.permissionService.getResource(resourceServerId, resourceId).subscribe(resource => this.scopes = resource.scopes);
      }
      (this.scopesMultiSelect as any).value = null;
      this.scopesMultiSelect.enabled = true
    }
  }

  public setPolicyField = {
    text: "label",
    value: "label"
  }

  public setResourceServerField = {
    text: "clientId",
    value: "id"
  }

  public setResourceField = {
    text: "name",
    value: "_id"
  }

  public setScopeField = {
    text: "name",
    value: "id"
  }

  ngAfterViewInit(): void {
    this.modalButton.element.focus();
  }

  // On Button click, modal Dialog will be shown
  public modalBtnClick = (): void => {
    this.modalDialog.show();
  }
  // On Dialog close, 'Open' Button will be shown
  public modalDlgClose = (): void => {
    this.modalButton.element.style.display = '';
  }
  // On Dialog open, 'Open' Button will be hidden
  public modalDlgOpen = (): void => {
    // deselect resource & scope
    (this.taskDomainsComboBox as any).value = null;
    (this.tasksComboBox as any).value = null;
    (this.resourceServersComboBox as any).value = null;
    ( this.resourceServersComboBox as any).value = null;
    this.resourcesComboBox.enabled = false;
    (this.resourcesComboBox as any).value = null;
    (this.scopesMultiSelect as any).value = null;
    this.scopesMultiSelect.enabled = false
    this.modalButton.element.style.display = 'none';
    this.resourceServers = this.permissionService.resourceServers;
    this.permissionService.getTasks('TASKDOMAIN:').subscribe(tasks => this.taskDomains = tasks);
  }

  // Close the Dialog, while clicking "OK" Button of Dialog
  public dlgButtonClick = (): void => {
    let taskDomainLabel = this.taskDomainsComboBox.value.toString();
    let taskLabel = this.tasksComboBox.value.toString();
    let resourceServerId = this.resourceServersComboBox.value.toString();
    let resourceId = this.resourcesComboBox.value.toString();
    let optionalScopeIds: number[] | string[] | boolean[] = this.scopesMultiSelect.value;
    this.permissionService.createPermission(taskDomainLabel, taskLabel, resourceServerId, resourceId, optionalScopeIds)
      .subscribe(response => {
        this.modalDialog.hide();
        this.newPermissionEvent.emit({
          taskLabel: taskLabel,
          taskDomainLabel: taskDomainLabel
        })
      })
  }

  // Initialize Button to open the modal Dialog

  public buttons: { [key: string]: ButtonModel }[] = [{
    // @ts-ignore
    click: this.dlgButtonClick.bind(this),
    buttonModel: {content: 'SUBMIT', isPrimary: true}
  }];

}
