import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private router: Router) {
  }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request)
            .pipe(
                catchError((httpErrorResponse: HttpErrorResponse) => {
                  if(httpErrorResponse.error.status == 401) {
                    alert('not allowed or token expired');
                  } else if(httpErrorResponse.error.status == 403) {
                    alert('forbidden');
                  } else {
                    alert(httpErrorResponse.message)
                  }
                  return throwError(httpErrorResponse);
                })
            );
    }

  private getTechnicalErrorMessage(error: any) {
    switch (error.status) {
      case 401:
      case 403:
        this.router.navigate(['/']).then();
        return 'not allowed or token expired';
      default:
        return error;
    }
  }

}
