import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {environment} from '../environments/environment';
import {KeycloakService} from 'keycloak-angular';
import {Policy, Resource, ResourceServer, Scope} from "./business-objects";
import {first, firstValueFrom, forkJoin, lastValueFrom, map, mergeMap, Observable, tap} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class PermissionsService {

    resourceServers: ResourceServer[] = []

    constructor(
        private http: HttpClient,
        private readonly keycloak: KeycloakService
    ) {
    }

    /**
     create subtask policy (if not exist)
     create task policy and add subtask (if not exist)
     create resource (if not exist)
     create optional scopes in resource (if not exist)
     create permission (if not exist)
     */
    public createPermission(taskDomainLabel: string, taskLabel: string, resourceServerId: string, resourceId: string, optionalScopeIds: number[] | string[] | boolean[]) {
        return new Observable<string>(observer => {
            let taskDomainName = 'TASKDOMAIN:'.concat(taskDomainLabel);
            this.getPolicies(resourceServerId, taskDomainName).subscribe(async taskDomains => {
                const superAdminPolicyId = await firstValueFrom(this.getPolicies(resourceServerId, 'ROLE:ROLE_ADMIN_PLATANA'));
                if(superAdminPolicyId.length == 0) {
                  alert('authz service has no ROLE:ROLE_ADMIN_PLATANA policy')
                }
                let taskDomain: Policy;
                if (taskDomains.length > 0) {
                    taskDomain = taskDomains[0]
                } else {
                    let policies: string[] = []
                    policies.push(superAdminPolicyId[0].id)
                    taskDomain = await firstValueFrom(this.createPolicy(resourceServerId, taskDomainName, policies));
                }
                let taskName = 'TASK:'.concat(taskDomainLabel).concat('/').concat(taskLabel)
                this.getPolicies(resourceServerId, taskName).subscribe(async tasks => {
                    let task: Policy;
                    if (tasks.length > 0) {
                        task = tasks[0]
                        console.log(task.name + ' found')
                    } else {
                        let policies: string[] = []
                        policies.push(superAdminPolicyId[0].id)
                        policies.push(taskDomain.id)
                        task = await firstValueFrom(this.createPolicy(resourceServerId, taskName, policies));
                        console.log(task.name + ' created')
                    }

                    // scopes
                    let scopeIds: string[] = [];
                    if (optionalScopeIds != null && optionalScopeIds.length > 0) {
                        for (const scopeId of optionalScopeIds) {
                            if (this.isUUID(scopeId.toString())) {
                                scopeIds.push(scopeId.toString());
                            } else {
                                scopeIds.push((await firstValueFrom(this.createScope(resourceServerId, scopeId.toString()))).id);
                                console.log('scope created ' + scopeId.toString())
                            }
                        }
                    }

                    // resource
                    let resourceName = ''
                    if (this.isUUID(resourceId)) {
                        let ressource = await firstValueFrom(this.getResource(resourceServerId, resourceId));
                        resourceName = ressource.name
                        if (scopeIds.length > 0) {
                            console.log('update resource ' + resourceId)
                            for (const scopeId of scopeIds) {
                                if (!ressource.scopes) {
                                  ressource.scopes = []
                                }
                                ressource.scopes.push({
                                    name: scopeId,
                                    id: scopeId
                                })
                            }
                            await firstValueFrom(this.updateResourceScopes(resourceServerId, ressource));
                        }
                    } else {
                        console.log('create resource ' + resourceId)
                        let resource = await firstValueFrom(this.createResource(resourceServerId, resourceId, scopeIds));
                        resourceId = resource._id;
                        resourceName = resource.name;
                    }

                    let type = 'scope'
                    let permissionName = 'PERMISSION:'
                        .concat(taskDomainLabel)
                        .concat('/')
                        .concat(taskLabel)
                        .concat(':')
                        .concat(resourceName);
                    if (scopeIds.length > 0) {
                        permissionName = permissionName.concat(':')
                        for (const scopeId of scopeIds) {
                            const scope = (await lastValueFrom(this.getScope(resourceServerId, scopeId)));
                            permissionName = permissionName.concat(scope.name).concat(',')
                        }
                        permissionName = permissionName.substring(0, permissionName.length - 1)
                    } else {
                        permissionName = permissionName.concat(':')
                        const scope = (await lastValueFrom(this.getScopeByName(resourceServerId, 'noscope')));
                        scopeIds.push(scope.id)
                        permissionName = permissionName.concat(scope.name)
                    }

                    // permission
                    const body = {
                        "type": type,
                        "logic": "POSITIVE",
                        "decisionStrategy": "AFFIRMATIVE",
                        "name": permissionName,
                        "resources": [
                            resourceId
                        ],
                        "scopes": scopeIds,
                        "policies": [
                            task.id
                        ]
                    }

                    // exec
                    let token: string = this.keycloak.getKeycloakInstance().token ?? "";
                    const options = {
                        headers: new HttpHeaders()
                            .set('Authorization', 'Bearer '.concat(token)),
                    };
                    this.http.post<Policy>(this.getRealmBaseUrl().concat('/clients/')
                        .concat(resourceServerId)
                        .concat('/authz/resource-server/permission/')
                        .concat(type), body, options)
                        .subscribe(permission => {
                            // callback
                            observer.next('good')
                        })
                })
            })
        });
    }

    deletePermission(resourceServerId: string, permissionId: string) {
      let token: string = this.keycloak.getKeycloakInstance().token ?? "";
      const options = {
        headers: new HttpHeaders()
          .set('Authorization', 'Bearer '.concat(token)),
      };
      return this.http.delete<void>(this.getRealmBaseUrl().concat('/clients/').concat(resourceServerId).concat('/authz/resource-server/permission/').concat(permissionId), options)
    }

    createScope(resourceServerId: string, name: string) {
        let token: string = this.keycloak.getKeycloakInstance().token ?? "";
        const options = {
            headers: new HttpHeaders()
                .set('Authorization', 'Bearer '.concat(token)),
        };
        const body = {"name": name}
        return this.http.post<Scope>(this.getRealmBaseUrl().concat('/clients/').concat(resourceServerId).concat('/authz/resource-server/scope'), body, options)
    }

    createResource(resourceServerId: string, uri: string, scopeIds: string[]) {
        let token: string = this.keycloak.getKeycloakInstance().token ?? "";
        const options = {
            headers: new HttpHeaders()
                .set('Authorization', 'Bearer '.concat(token)),
        };
        let scopes = []
        if (scopeIds.length > 0) {
            for (const scopeId of scopeIds) {
                let scope = {
                    id: scopeId
                }
                scopes.push(scope)
            }
        }
        let body = {
            "attributes": {},
            "uris": [
                uri
            ],
            "name": uri,
            "ownerManagedAccess": "",
            "scopes": scopes
        }
        return this.http.post<Resource>(this.getRealmBaseUrl().concat('/clients/').concat(resourceServerId).concat('/authz/resource-server/resource'), body, options)
    }

    updateResourceScopes(resourceServerId: string, resource: Resource) {
        let token: string = this.keycloak.getKeycloakInstance().token ?? "";
        const options = {
            headers: new HttpHeaders()
                .set('Authorization', 'Bearer '.concat(token)),
        };
        return this.http.put<Resource>(this.getRealmBaseUrl().concat('/clients/').concat(resourceServerId).concat('/authz/resource-server/resource/').concat(resource._id), resource, options)
    }

    createPolicy(resourceServerId: string, name: string, policies: string[]) {
        let token: string = this.keycloak.getKeycloakInstance().token ?? "";
        const options = {
            headers: new HttpHeaders()
                .set('Authorization', 'Bearer '.concat(token)),
        };
        const body = {
            "type": "aggregate",
            "logic": "POSITIVE",
            "decisionStrategy": "AFFIRMATIVE",
            "name": name,
            "policies": policies
        }
        return this.http.post<Policy>(this.getRealmBaseUrl().concat('/clients/').concat(resourceServerId).concat('/authz/resource-server/policy/aggregate'), body, options)
    }

    private getResourceServers() {
        let token: string = this.keycloak.getKeycloakInstance().token ?? '';
        const options = {
            headers: new HttpHeaders()
                .set('Authorization', 'Bearer '.concat(token))
        };
        return this.http.get<ResourceServer[]>(this.getRealmBaseUrl().concat('/clients'), options)
            .pipe(map(clients => clients.filter(client => client.enabled && client.authorizationServicesEnabled)))
    }

    public getCachedResourceServers() {
        if (this.resourceServers.length > 0) {
            return new Observable<ResourceServer[]>(observer => observer.next(this.resourceServers));
        } else {
            return this.getResourceServers()
        }
    }

    getPolicies(resourceServerId: string, searchPattern: string) {
        let token: string = this.keycloak.getKeycloakInstance().token ?? "";
        const options = {
            headers: new HttpHeaders()
                .set('Authorization', 'Bearer '.concat(token)),
            params: new HttpParams()
                .set('name', searchPattern)
        };
        return this.http.get<Policy[]>(this.getRealmBaseUrl().concat('/clients/').concat(resourceServerId).concat('/authz/resource-server/policy'), options)
            .pipe(tap(policies => policies.map(policy => policy.clientId = resourceServerId)))
    }

    getAssociatedPolicies(resourceServerId: string, permissionId: string) {
        let token: string = this.keycloak.getKeycloakInstance().token ?? "";
        const options = {
            headers: new HttpHeaders()
                .set('Authorization', 'Bearer '.concat(token))
        };
        return this.http.get<Policy[]>(this.getRealmBaseUrl()
            .concat('/clients/')
            .concat(resourceServerId)
            .concat('/authz/resource-server/policy/')
            .concat(permissionId)
            .concat('/associatedPolicies'), options)
    }

    getAssociatedResources(resourceServerId: string, permissionId: string) {
        let token: string = this.keycloak.getKeycloakInstance().token ?? "";
        const options = {
            headers: new HttpHeaders()
                .set('Authorization', 'Bearer '.concat(token))
        };
        return this.http.get<Resource[]>(this.getRealmBaseUrl()
            .concat('/clients/')
            .concat(resourceServerId)
            .concat('/authz/resource-server/permission/')
            .concat(permissionId)
            .concat('/resources'), options)
    }

    getAssociatedScopes(resourceServerId: string, permissionId: string) {
        let token: string = this.keycloak.getKeycloakInstance().token ?? "";
        const options = {
            headers: new HttpHeaders()
                .set('Authorization', 'Bearer '.concat(token))
        };
        return this.http.get<Scope[]>(this.getRealmBaseUrl()
            .concat('/clients/')
            .concat(resourceServerId)
            .concat('/authz/resource-server/permission/')
            .concat(permissionId)
            .concat('/scopes'), options)
    }

    getResource(resourceServerId: string, resourceId: string) {
        let token: string = this.keycloak.getKeycloakInstance().token ?? "";
        const options = {
            headers: new HttpHeaders()
                .set('Authorization', 'Bearer '.concat(token))
        };
        return this.http.get<Resource>(this.getRealmBaseUrl()
            .concat('/clients/')
            .concat(resourceServerId)
            .concat('/authz/resource-server/resource/')
            .concat(resourceId), options)
    }

    getScope(resourceServerId: string, scopeId: string) {
        let token: string = this.keycloak.getKeycloakInstance().token ?? "";
        const options = {
            headers: new HttpHeaders()
                .set('Authorization', 'Bearer '.concat(token))
        };
        return this.http.get<Scope>(this.getRealmBaseUrl()
            .concat('/clients/')
            .concat(resourceServerId)
            .concat('/authz/resource-server/scope/')
            .concat(scopeId), options)
    }

    getScopeByName(resourceServerId: string, scope: string): Observable<Scope> {
      // https://login.develop.platana.fr/auth/admin/realms/SAV-DIGITAL/clients/fbaba3bd-9fd1-478f-81c8-625c6df6b7fc/authz/resource-server/scope?deep=false&first=0&max=11&name=scope
      let token: string = this.keycloak.getKeycloakInstance().token ?? "";
      const options = {
        headers: new HttpHeaders()
          .set('Authorization', 'Bearer '.concat(token))
      };
      return this.http.get<Scope[]>(this.getRealmBaseUrl()
        .concat('/clients/')
        .concat(resourceServerId)
        .concat('/authz/resource-server/scope?deep=false&name=')
        .concat(scope), options).pipe(mergeMap(scopes => scopes.filter(scp => scp.name === scope)));
    }

    getResources(resourceServerId: string) {
        let token: string = this.keycloak.getKeycloakInstance().token ?? "";
        const options = {
            headers: new HttpHeaders()
                .set('Authorization', 'Bearer '.concat(token))
        };
        return this.http.get<Resource[]>(this.getRealmBaseUrl()
            .concat('/clients/')
            .concat(resourceServerId)
            .concat('/authz/resource-server/resource'), options)
    }

    private getRealmBaseUrl() {
        return environment.keycloakUrl.concat('/admin/realms/').concat(environment.keycloakRealm);
    }

    getTasks(searchPattern: string): Observable<Policy[]> {
        return new Observable<Policy[]>(observer => {
            this.getCachedResourceServers().subscribe(resourceServers => {
                this.resourceServers = resourceServers;
                let taskDomainsMap = new Map<string, Policy>();
                let policiesRequests: Observable<Policy[]>[] = []
                this.resourceServers.forEach(resourceServer => {
                    policiesRequests.push(this.getPolicies(resourceServer.id, searchPattern));
                })
                forkJoin(policiesRequests).subscribe(policiesResponses => {
                    policiesResponses.forEach(policiesResponse => {
                        policiesResponse.forEach(policy => {
                            // remove type
                            policy.label = policy.name.split(':')[1]
                            let arb = policy.label.split('/');
                            if (arb.length > 1) {
                                // remove domain if task
                                let domain = policy.label.split('/')[0]
                                policy.label = policy.label.substring(domain.length + 1, policy.label.length)
                            }
                            taskDomainsMap.set(policy.label, policy)
                        })
                    })
                    let taskDomains: Policy[] = []
                    for (let task of taskDomainsMap.values()) {
                        taskDomains.push(task)
                    }
                    observer.next(taskDomains);
                })
            })
        });

    }


    getPermissions(searchPattern: string): Observable<Map<string, Policy[]>> {
        return new Observable<Map<string, Policy[]>>(observer => {
            this.getCachedResourceServers().subscribe(resourceServers => {
                this.resourceServers = resourceServers;

                let rs2permissions: Map<string, Policy[]> = new Map<string, Policy[]>;
                let policiesRequests: Observable<Policy[]>[] = []
                this.resourceServers.forEach(resourceServer => {
                    policiesRequests.push(this.getPolicies(resourceServer.id, searchPattern));
                })
                forkJoin(policiesRequests).subscribe(async policiesResponses => {
                    for (const policiesResponse of policiesResponses) {
                        for (const policy of policiesResponse) {
                            // policy.associatedPolicies = await firstValueFrom(this.getAssociatedPolicies(policy.clientId, policy.id)) ?? []
                            policy.resources = await firstValueFrom(this.getAssociatedResources(policy.clientId, policy.id)) ?? []
                            policy.scopes = await firstValueFrom(this.getAssociatedScopes(policy.clientId, policy.id)) ?? []

                            for (const resource of policy.resources) {
                                const fullResource = await firstValueFrom(this.getResource(policy.clientId, resource._id))
                                if (fullResource) {
                                    resource.scopes = fullResource.scopes
                                    resource.uris = fullResource.uris
                                }
                            }

                            if (rs2permissions.has(policy.clientId)) {
                                rs2permissions.get(policy.clientId)?.push(policy)
                            } else {
                                let policies: Policy[] = []
                                policies.push(policy)
                                rs2permissions.set(policy.clientId, policies)
                            }
                        }
                    }
                    observer.next(rs2permissions);
                })
            })
        });


    }

    isUUID(uuid: string): boolean {
        let s = "" + uuid;
        let res = s.match('^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$');
        return res !== null;
    }
}
